/************************************
     CORE FILE TO IMPORT STYLES
           STYLE
************************************/
/************************************
            VARIABLES
************************************/
/* Font */
/* Grid */
/* Colors */
/************************************
               MIXINS
************************************/
/* Clearfix for parent of floated elements */
/* Px to Rem Unit */
/* Responsive properties depend on max-width
  since we use desktop first design */
/************************************
     CONFIGURATION & RESET
************************************/
/****** GOOGLE FONTS IMPORT ******/
*, *:before, *:after {
  box-sizing: border-box;
  /*** Optional ***/
  margin: 0;
  padding: 0; }

html, body {
  margin: 0;
  padding: 0;
  /***  Optional ***/ }

html {
  font-size: 1rem; }

body {
  font-family: "Inter UI", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  text-align: center;
  color: #444950; }

a {
  color: inherit;
  /*** Or ***/
  text-decoration: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ol, ul {
  list-style: none; }

h1, h2 {
  color: #1C1E21;
  font-size: 4rem;
  line-height: 4.375rem;
  margin-bottom: 1rem; }
  @media screen and (max-width: 767px) {
    h1, h2 {
      font-size: 2.625rem;
      line-height: 3.125rem; }
      h1 br, h2 br {
        display: none; } }
  @media screen and (max-width: 480px) {
    h1, h2 {
      font-size: 2rem;
      line-height: 2.5rem; } }

h3 {
  color: #444950;
  font-size: 1.75rem;
  line-height: 2.375rem;
  margin-bottom: 3.5rem;
  font-weight: 400; }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 1.125rem;
      line-height: 1.625rem;
      margin-bottom: 2rem; }
      h3 br {
        display: none; } }

section {
  padding: 7.5rem 0; }
  section.sc-dark {
    background-color: #23272F; }
    section.sc-dark h1, section.sc-dark h2 {
      color: white; }
      section.sc-dark h1 a, section.sc-dark h2 a {
        text-decoration: underline; }
    section.sc-dark h3 {
      color: #cccccc; }
      section.sc-dark h3 a {
        text-decoration: underline; }
  section .section-header {
    max-width: 51rem;
    margin: 0 auto; }
  @media screen and (max-width: 767px) {
    section {
      padding: 5rem 0; } }

/************************************
      RESPONSIVE GRID SYSTEM

         by: Doga Genc
         (15.12.2015)
************************************/
/* Centered container  */
.container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0 15px;
  position: relative;
  overflow: hidden; }
  .container:after {
    content: " ";
    display: table;
    clear: both; }
  .container.tight {
    padding: 0 rem-calc(50); }
    @media screen and (max-width: 767px) {
      .container.tight {
        padding: 0 15px; } }

/* Full width container */
.container-fluid {
  position: relative; }
  .container-fluid:after {
    content: " ";
    display: table;
    clear: both; }

.row {
  margin-left: -10px;
  margin-right: -10px; }
  .row:after {
    content: " ";
    display: table;
    clear: both; }
  .row > .col {
    padding-left: 10px;
    padding-right: 10px; }
  .row.no-gutter {
    margin-left: 0;
    margin-right: 0; }
    .row.no-gutter > .col {
      padding-left: 0;
      padding-right: 0; }
  .row.gutter-md {
    margin-left: -12px;
    margin-right: -12px; }
    .row.gutter-md > .col {
      padding-left: 12px;
      padding-right: 12px; }
  .row.gutter-lg {
    margin-left: -20px;
    margin-right: -20px; }
    .row.gutter-lg > .col {
      padding-left: 20px;
      padding-right: 20px; }
  .row.row-right > .col {
    float: right; }

.col {
  float: left;
  min-height: 1px; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

@media screen and (max-width: 1040px) {
  .col-lg-1 {
    width: 8.33333%; } }

@media screen and (max-width: 1040px) {
  .col-lg-2 {
    width: 16.66667%; } }

@media screen and (max-width: 1040px) {
  .col-lg-3 {
    width: 25%; } }

@media screen and (max-width: 1040px) {
  .col-lg-4 {
    width: 33.33333%; } }

@media screen and (max-width: 1040px) {
  .col-lg-5 {
    width: 41.66667%; } }

@media screen and (max-width: 1040px) {
  .col-lg-6 {
    width: 50%; } }

@media screen and (max-width: 1040px) {
  .col-lg-7 {
    width: 58.33333%; } }

@media screen and (max-width: 1040px) {
  .col-lg-8 {
    width: 66.66667%; } }

@media screen and (max-width: 1040px) {
  .col-lg-9 {
    width: 75%; } }

@media screen and (max-width: 1040px) {
  .col-lg-10 {
    width: 83.33333%; } }

@media screen and (max-width: 1040px) {
  .col-lg-11 {
    width: 91.66667%; } }

@media screen and (max-width: 1040px) {
  .col-lg-12 {
    width: 100%; } }

@media screen and (max-width: 996px) {
  .col-md-1 {
    width: 8.33333%; } }

@media screen and (max-width: 996px) {
  .col-md-2 {
    width: 16.66667%; } }

@media screen and (max-width: 996px) {
  .col-md-3 {
    width: 25%; } }

@media screen and (max-width: 996px) {
  .col-md-4 {
    width: 33.33333%; } }

@media screen and (max-width: 996px) {
  .col-md-5 {
    width: 41.66667%; } }

@media screen and (max-width: 996px) {
  .col-md-6 {
    width: 50%; } }

@media screen and (max-width: 996px) {
  .col-md-7 {
    width: 58.33333%; } }

@media screen and (max-width: 996px) {
  .col-md-8 {
    width: 66.66667%; } }

@media screen and (max-width: 996px) {
  .col-md-9 {
    width: 75%; } }

@media screen and (max-width: 996px) {
  .col-md-10 {
    width: 83.33333%; } }

@media screen and (max-width: 996px) {
  .col-md-11 {
    width: 91.66667%; } }

@media screen and (max-width: 996px) {
  .col-md-12 {
    width: 100%; } }

@media screen and (max-width: 767px) {
  .col-sm-1 {
    width: 8.33333%; } }

@media screen and (max-width: 767px) {
  .col-sm-2 {
    width: 16.66667%; } }

@media screen and (max-width: 767px) {
  .col-sm-3 {
    width: 25%; } }

@media screen and (max-width: 767px) {
  .col-sm-4 {
    width: 33.33333%; } }

@media screen and (max-width: 767px) {
  .col-sm-5 {
    width: 41.66667%; } }

@media screen and (max-width: 767px) {
  .col-sm-6 {
    width: 50%; } }

@media screen and (max-width: 767px) {
  .col-sm-7 {
    width: 58.33333%; } }

@media screen and (max-width: 767px) {
  .col-sm-8 {
    width: 66.66667%; } }

@media screen and (max-width: 767px) {
  .col-sm-9 {
    width: 75%; } }

@media screen and (max-width: 767px) {
  .col-sm-10 {
    width: 83.33333%; } }

@media screen and (max-width: 767px) {
  .col-sm-11 {
    width: 91.66667%; } }

@media screen and (max-width: 767px) {
  .col-sm-12 {
    width: 100%; } }

@media screen and (max-width: 480px) {
  .col-xs-1 {
    width: 8.33333%; } }

@media screen and (max-width: 480px) {
  .col-xs-2 {
    width: 16.66667%; } }

@media screen and (max-width: 480px) {
  .col-xs-3 {
    width: 25%; } }

@media screen and (max-width: 480px) {
  .col-xs-4 {
    width: 33.33333%; } }

@media screen and (max-width: 480px) {
  .col-xs-5 {
    width: 41.66667%; } }

@media screen and (max-width: 480px) {
  .col-xs-6 {
    width: 50%; } }

@media screen and (max-width: 480px) {
  .col-xs-7 {
    width: 58.33333%; } }

@media screen and (max-width: 480px) {
  .col-xs-8 {
    width: 66.66667%; } }

@media screen and (max-width: 480px) {
  .col-xs-9 {
    width: 75%; } }

@media screen and (max-width: 480px) {
  .col-xs-10 {
    width: 83.33333%; } }

@media screen and (max-width: 480px) {
  .col-xs-11 {
    width: 91.66667%; } }

@media screen and (max-width: 480px) {
  .col-xs-12 {
    width: 100%; } }

/************************************
            HELPERS
************************************/
/****** SIZING *******/
.fill-width {
  width: 100%; }

.fill-height {
  height: 100%; }

.mw-100 {
  max-width: 100%; }

.mh-100 {
  max-height: 100%; }

/****** POSITIONING ******/
.pull-left {
  float: left; }

.pull-right {
  float: right; }

.clearfix:after {
  content: " ";
  display: table;
  clear: both; }

/****** TYPOGRAPHY ******/
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.hide {
  display: none; }

@media screen and (max-width: 996px) {
  .show-md {
    display: block !important; }
  .hide-md {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .show-sm {
    display: block !important; }
  .hide-sm {
    display: none !important; } }

@font-face {
  font-family: 'Inter UI';
  src: url("../fonts/inter-ui-regular-webfont.woff2") format("woff2"), url("../fonts/inter-ui-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Inter UI';
  src: url("../fonts/inter-ui-medium-webfont.woff2") format("woff2"), url("../fonts/inter-ui-medium-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Inter UI';
  src: url("../fonts/inter-ui-bold-webfont.woff2") format("woff2"), url("../fonts/inter-ui-bold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

.btn {
  display: inline-block;
  padding: 1.0625rem 1.6875rem;
  font-family: "Inter UI", sans-serif;
  font-size: 1.125rem;
  background-color: transparent;
  color: #444950;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 800; }
  .btn:focus, .btn:active {
    outline: none; }
  .btn-group .btn {
    margin: 0 0.75rem; }
  .btn.btn-primary {
    color: white;
    background-color: #00D061; }
    .btn.btn-primary:hover {
      background-color: #00da66; }
  .btn.btn-light {
    color: #444950;
    background-color: #EBEDF0; }
    .btn.btn-light:hover {
      background-color: #e2e5e9; }
  .btn.btn-dark {
    color: white;
    background-color: rgba(141, 148, 158, 0.2);
    border: none; }
    .btn.btn-dark:hover {
      background-color: rgba(141, 148, 158, 0.25); }
  .btn.btn-sm {
    padding: 0.625rem 0.9375rem;
    font-size: 1rem; }
  @media screen and (max-width: 767px) {
    .btn {
      width: 100%; }
      .btn-group .btn {
        margin: 0.5rem 0; } }

/*** PARTIALS ***/
/************************************
          PARTIALS: HEADER
************************************/
header {
  position: fixed;
  width: calc(100% - 48px);
  left: 48px;
  top: 0;
  background-color: white;
  padding: 1.75rem 2.5rem;
  box-shadow: 0 0.125rem 2.5rem rgba(0, 0, 0, 0.04);
  z-index: 10;
  transition: all 0.3s; }
  header:after {
    content: " ";
    display: table;
    clear: both; }
  header.scrolled {
    transform: translateY(-100%);
    box-shadow: none; }
  header .hd-logo {
    float: left;
    margin-top: 0.125rem; }
    header .hd-logo img {
      height: 2rem; }
  header nav {
    float: right;
    padding-top: 0.5rem; }
    header nav li {
      display: inline-block;
      margin-left: 2.5rem;
      font-weight: bold; }
      header nav li a {
        position: relative;
        cursor: pointer; }
        header nav li a:after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 0;
          bottom: 0;
          background-color: #444950;
          transition: width 0.1s; }
        header nav li a:hover:after {
          width: 100%; }
  @media screen and (max-width: 767px) {
    header {
      padding: 0.875rem 0 0.5rem; }
      header nav {
        display: none; }
      header .hd-logo {
        float: none; }
        header .hd-logo img {
          height: 2rem; } }

.ft-newsletter a {
  color: #00D061;
  font-size: 1.375rem; }
  .ft-newsletter a:hover {
    color: #00f974; }

.ft-newsletter .mb-success-message,
.ft-newsletter .mb-error-message {
  margin-top: 2.5rem;
  color: white;
  font-size: 1.25rem; }

.ft-newsletter form {
  margin-top: 2.5rem; }
  .ft-newsletter form .mb-form-group {
    display: inline-block;
    position: relative; }
    .ft-newsletter form .mb-form-group #mb-email:focus + #mb-email-error {
      display: block !important; }
    .ft-newsletter form .mb-form-group #mb-email-error {
      display: none !important;
      position: absolute;
      width: auto;
      left: 30px;
      top: 100%;
      margin-top: 15px;
      color: white;
      text-transform: italic; }
  .ft-newsletter form .mb-form-group-tk {
    position: absolute;
    left: -5000px; }
  .ft-newsletter form input {
    display: inline-block;
    width: 25rem;
    padding: 0 1.875rem;
    height: 3.8125rem;
    font-family: "Inter UI", sans-serif;
    font-size: 1.125rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 0.5rem; }
    .ft-newsletter form input:focus, .ft-newsletter form input:active {
      outline: none; }
  .ft-newsletter form .btn {
    border: none;
    margin-left: 1.5rem; }

@media screen and (max-width: 767px) {
  .ft-newsletter form {
    margin-top: 0; }
    .ft-newsletter form .mb-form-group {
      display: block; }
      .ft-newsletter form .mb-form-group #mb-email-error {
        top: 0;
        margin-top: -10px; }
    .ft-newsletter form input {
      width: 100%;
      margin-top: 1.25rem; }
    .ft-newsletter form .btn {
      width: 100%;
      margin-left: 0;
      margin-top: 0.875rem; } }

.ft-nav {
  margin-top: 7.5rem; }
  .ft-nav li {
    display: inline-block;
    margin: 0 2.1875rem 0.875rem; }
    .ft-nav li a {
      display: block;
      color: #BEC3C9;
      font-size: 1rem;
      font-weight: 600; }
      .ft-nav li a:hover {
        color: white; }
  @media screen and (max-width: 767px) {
    .ft-nav {
      margin-top: 4.375rem; }
      .ft-nav li {
        display: block;
        margin-bottom: 1.25rem; } }

/*** PAGES ***/
/************************************
        PAGES: HOME (INDEX)
************************************/
.main-content {
  position: relative;
  margin-top: 98px; }
  @media screen and (max-width: 767px) {
    .main-content {
      margin-top: 3.75rem; } }

.cta {
  margin-top: 5rem; }
  @media screen and (max-width: 767px) {
    .cta {
      margin-top: 2.5rem; } }

.confetti-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  overflow: hidden; }

.hero {
  position: relative;
  background-image: url("../img/hero-bg.jpg");
  background-size: 100% auto;
  background-position: left 30%;
  background-repeat: no-repeat;
  padding-bottom: 5rem; }
  .hero .mb-embed .mb-message {
    font-size: 1.125rem;
    line-height: 1.4; }
    .hero .mb-embed .mb-message.mb-error-message {
      color: #F35369; }
  .hero .mb-embed .mb-email {
    display: block;
    width: 100%;
    padding: 1.0625rem 1.6875rem;
    font-family: "Inter UI", sans-serif;
    font-size: 1.125rem;
    background-color: transparent;
    color: #444950;
    border-radius: 0.5rem;
    text-align: center;
    border: solid 2px #00D061; }
  .hero .mb-embed .mb-subscribe button {
    border: none;
    margin-top: 16px; }
  @media screen and (max-width: 767px) {
    .hero {
      padding-bottom: 2.5rem; }
      .hero .hero-video-wrapper {
        position: fixed;
        left: 0;
        top: 0;
        margin-top: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
        opacity: 0;
        pointer-events: none;
        transition: all 0.6s;
        background-color: rgba(255, 255, 255, 0.9); }
        .hero .hero-video-wrapper.video-playing {
          opacity: 1;
          pointer-events: initial; }
        .hero .hero-video-wrapper .hero-video-image,
        .hero .hero-video-wrapper .hero-video-button {
          display: none; }
        .hero .hero-video-wrapper .video-wrapper {
          position: absolute;
          opacity: 1;
          height: 0;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 0;
          padding-bottom: 56%; }
          .hero .hero-video-wrapper .video-wrapper #hero-video {
            position: absolute;
            left: 0;
            top: 0; } }

.hero-video-wrapper {
  position: relative;
  margin-top: 3.75rem; }
  .hero-video-wrapper.video-playing .video-wrapper {
    opacity: 1; }
  .hero-video-wrapper.video-playing .hero-video-image,
  .hero-video-wrapper.video-playing .hero-video-button {
    opacity: 0;
    pointer-events: none; }
  .hero-video-wrapper .video-wrapper {
    position: absolute;
    padding: 5% 5%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 1s; }
    .hero-video-wrapper .video-wrapper #hero-video {
      width: 100%;
      height: 100%; }
  .hero-video-wrapper .hero-video-image {
    max-width: 100%;
    vertical-align: top;
    transition: all 1s; }
  .hero-video-wrapper .hero-video-button {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6.625rem;
    height: 6.625rem;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 0.125rem 3.375rem rgba(0, 0, 0, 0.5);
    transition: opacity 1s, box-shadow 0.3s;
    z-index: 1; }
    .hero-video-wrapper .hero-video-button:hover {
      box-shadow: 0 0.125rem 6.25rem rgba(0, 0, 0, 0.7); }
      .hero-video-wrapper .hero-video-button:hover img {
        opacity: 0.8; }
    .hero-video-wrapper .hero-video-button img {
      position: relative;
      left: -0.1875rem;
      transition: all 0.3s; }

.feeds {
  padding-bottom: 2.5rem; }
  .feeds .feed-wrapper {
    padding-top: 6.25rem; }
    .feeds .feed-wrapper img {
      display: inline-block;
      height: 5rem; }
    .feeds .feed-wrapper.fw-bg {
      background-image: url("../img/browser.svg");
      background-repeat: no-repeat;
      background-size: 100% auto;
      padding-top: 30%;
      padding-bottom: 3.75rem; }
      .feeds .feed-wrapper.fw-bg img {
        height: 6.25rem; }
  @media screen and (max-width: 996px) {
    .feeds .feed-wrapper {
      background-image: none !important;
      padding-top: 2.5rem !important;
      padding-bottom: 0 !important; }
      .feeds .feed-wrapper img {
        height: 3.75rem !important; } }

.feeds-owl {
  padding: 6.875rem 0 5rem;
  background-image: url("../img/browser.svg");
  background-repeat: no-repeat;
  background-position: top center; }
  .feeds-owl .owl-img-wrapper {
    padding: 2.5rem; }
  .feeds-owl .owl-item.el-1 img {
    transform: translateX(-50%); }
  .feeds-owl .owl-item.el-2 img {
    transform: translateX(-80%); }
  .feeds-owl .owl-item.el-3 img {
    transform: scale3d(1.5, 1.5, 1.5); }
  .feeds-owl .owl-item.el-4 img {
    transform: translateX(80%); }
  .feeds-owl .owl-item.el-5 img {
    transform: translateX(50%); }
  .feeds-owl img {
    display: inline-block !important;
    max-width: 5rem;
    transition: transform 0.6s linear; }

.inspiration .quote-card {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 1.5rem;
  background-color: #F9F9F9;
  border-radius: 0.625rem; }
  .inspiration .quote-card .quote {
    margin-bottom: 1rem; }
  .inspiration .quote-card .author {
    color: #00D061;
    font-weight: 800; }

.inspiration .btn-group {
  margin: 5rem 0 2.5rem; }

.inspiration .inspiration-companies {
  color: #606770;
  font-size: 1.125rem; }
  .inspiration .inspiration-companies .company-logos {
    margin-top: 40px; }
    .inspiration .inspiration-companies .company-logos img {
      height: 2.125rem;
      margin: 0 2.375rem; }

@media screen and (max-width: 767px) {
  .inspiration .quote-card {
    margin-bottom: 1.5rem; }
  .inspiration .btn-group {
    margin: 0 0 1.5rem; }
  .inspiration .inspiration-companies .company-logos {
    margin-top: 1.25rem; }
    .inspiration .inspiration-companies .company-logos img {
      margin: 0.75rem; } }

.support-products {
  padding-top: 2.5rem; }

.product-box {
  padding: 2.5rem 1.875rem 0;
  border-radius: 0.625rem;
  margin-top: 1.5625rem;
  overflow: hidden; }
  .product-box.pb-ios {
    background-color: #8C72CB; }
  .product-box.pb-android {
    background-color: #00D061;
    margin-top: 0; }
    .product-box.pb-android img {
      max-height: 12.5rem; }
  .product-box.pb-firefox {
    background-color: #23272F; }
  .product-box span {
    font-size: 0.8125rem;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8); }
  .product-box h4 {
    margin: 0.3125rem 0 0.9375rem;
    font-size: 1.375rem;
    font-weight: 800;
    color: white; }
  .product-box p {
    font-size: 1rem;
    color: white; }
  .product-box img {
    display: block;
    margin: 1.875rem auto 0;
    max-height: 9.375rem; }
  @media screen and (max-width: 996px) {
    .product-box img {
      max-width: 100%; } }
  @media screen and (max-width: 767px) {
    .product-box {
      margin-top: 0;
      margin-bottom: 1.5rem; }
      .product-box.pb-android img {
        position: relative;
        top: 3.125rem;
        margin-top: -1.875rem; } }

.support-features {
  margin-top: 5rem; }
  .support-features .feature-box {
    padding: 0 1.25rem;
    margin-bottom: 5rem; }
    .support-features .feature-box img {
      max-height: 4rem; }
    .support-features .feature-box h5 {
      margin: 0.3125rem 0 0.625rem;
      font-weight: 800;
      font-size: 1.375rem;
      color: #1C1E21; }
    .support-features .feature-box p {
      font-size: 1rem; }
  @media screen and (max-width: 767px) {
    .support-features {
      margin-top: 3.75rem; }
      .support-features .feature-box {
        margin-bottom: 2.5rem; } }

.folks-list a {
  display: block; }
  .folks-list a:hover h5:after {
    width: 100%; }

.folks-list img {
  display: block;
  max-width: 6.25rem;
  margin: 0 auto; }

.folks-list h5 {
  position: relative;
  display: inline-block;
  font-weight: 800;
  font-size: 1.375rem;
  color: #1C1E21;
  margin: 0.625rem 0; }
  .folks-list h5:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    top: 100%;
    background-color: #1C1E21;
    transition: width 0.2s; }

.folks-list p {
  margin-bottom: 5rem; }

@media screen and (max-width: 767px) {
  .folks-list p {
    margin-bottom: 2.5rem; } }

.terms {
  text-align: left;
  font-size: 1.25rem;
  line-height: 1.9375rem; }
  .terms a {
    text-decoration: underline; }
  @media screen and (max-width: 767px) {
    .terms {
      font-size: 1rem;
      line-height: 1.5rem; }
      .terms .term-title {
        font-size: 1.375rem; } }

.term-title {
  font-size: 1.75rem;
  color: #1C1E21;
  margin-bottom: 1rem; }

.term-desc {
  margin-bottom: 2.5rem; }

.term-list {
  list-style-type: disc;
  padding-left: 2.125rem;
  margin-bottom: 2.5rem; }
  .term-list li {
    padding-left: 0.4375rem;
    margin-bottom: 0.625rem; }

.term-video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  margin-bottom: 6.25rem; }

.term-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.mb-form-group-tk {
  position: absolute;
  left: -5000px; }
