/************************************
          PARTIALS: HEADER
************************************/
header {
  position: fixed;
  width: calc(100% - 48px);
  left: 48px;
  top: 0;
  background-color: white;
  @include clearfix;
  padding: rem(28) rem(40);
  box-shadow: 0 rem(2) rem(40) rgba(black, 0.04);
  z-index: 10;
  transition: all 0.3s;

  &.scrolled {
    transform: translateY(-100%);
    box-shadow: none;
  }

  .hd-logo {
    float: left;
    margin-top: rem(2);

    img {
      height: rem(32);
    }
  }

  nav {
    float: right;
    padding-top: rem(8);

    li {
      display: inline-block;
      margin-left: rem(40);
      font-weight: bold;

      a {
        position: relative;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 0;
          bottom: 0;
          background-color: $color-text;
          transition: width 0.1s;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  @include responsive($view-sm) {
    padding: rem(14) 0 rem(8);

    nav {
      display: none;
    }

    .hd-logo {
      float: none;
      img {
        height: rem(32);
      }
    }
  }
}
