.terms {
  text-align: left;
  font-size: rem(20);
  line-height: rem(31);

  a {
    text-decoration: underline;
  }

  @include responsive($view-sm) {
    font-size: rem(16);
    line-height: rem(24);

    .term-title {
      font-size: rem(22);
    }
  }
}

.term-title {
  font-size: rem(28);
  color: $color-title;
  margin-bottom: rem(16);
}

.term-desc {
  margin-bottom: rem(40);
}

.term-list {
  list-style-type: disc;
  padding-left: rem(34);
  margin-bottom: rem(40);

  li {
    padding-left: rem(7);
    margin-bottom: rem(10);
  }
}

.term-video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  margin-bottom: rem(100);
}

.term-video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

