/************************************
            HELPERS
************************************/

/****** SIZING *******/
.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.mw-100 {
  max-width: 100%;
}

.mh-100 {
  max-height: 100%;
}

/****** POSITIONING ******/
.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clearfix {
  @include clearfix;
}

/****** TYPOGRAPHY ******/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hide {
  display: none;
}

// .show-md {
//   @include responsive($view-md) {
//     display: block;
//   }
// }

@include responsive($view-md) {
  .show-md {
    display: block !important;
  }

  .hide-md {
    display: none !important;
  }
}

@include responsive($view-sm) {
  .show-sm {
    display: block !important;
  }

  .hide-sm {
    display: none !important;
  }
}
