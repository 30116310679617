.main-content {
  position: relative;
  margin-top: $header-height;

  @include responsive($view-sm) {
    margin-top: rem(60);
  }
}

.cta {
  margin-top: rem(80);
  
  @include responsive($view-sm) {
    margin-top: rem(40);
  }
}

.confetti-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.hero {
  position: relative;
  background-image: url('../img/hero-bg.jpg');
  background-size: 100% auto;
  background-position: left 30%;
  background-repeat: no-repeat;
  padding-bottom: rem(80);

  .mb-embed {
    .mb-message {
      font-size: rem(18);
      line-height: 1.4;

      &.mb-error-message {
        color: $color-error;
      }
    }

    .mb-email {
      display: block;
      width: 100%;
      padding: rem(17) rem(27);
      font-family: $global-font;
      font-size: rem(18);
      background-color: transparent;
      color: $color-text;
      border-radius: rem(8);
      text-align: center;
      border: solid 2px $color-primary;
    }

    .mb-subscribe button {
      border: none;
      margin-top: 16px;
    }
  }

  @include responsive($view-sm) {
    padding-bottom: rem(40);

    .hero-video-wrapper {
      position: fixed;
      left: 0;
      top: 0;
      margin-top: 0;
      width: 100%;
      height: 100%;
      z-index: 11;
      opacity: 0;
      pointer-events: none;
      transition: all 0.6s;
      background-color: rgba(white, 0.9);

      &.video-playing {
        opacity: 1;
        pointer-events: initial;
      }

      .hero-video-image,
      .hero-video-button {
        display: none;
      }

      .video-wrapper {
        position: absolute;
        opacity: 1;
        height: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        padding-bottom: 56%;

        #hero-video {
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
}

.hero-video-wrapper {
  position: relative;
  margin-top: rem(60);

  &.video-playing {
    .video-wrapper {
      opacity: 1;
    }

    .hero-video-image,
    .hero-video-button {
      opacity: 0;
      pointer-events: none;
    }
  }

  .video-wrapper {
    position: absolute;
    padding: 5% 5%;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 1s;

    #hero-video {
      width: 100%;
      height: 100%;
    }
  }

  .hero-video-image {
    max-width: 100%;
    vertical-align: top;
    transition: all 1s;
  }

  .hero-video-button {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: rem(106);
    height: rem(106);
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 rem(2) rem(54) rgba(black, 0.5);
    transition: opacity 1s, box-shadow 0.3s;
    z-index: 1;

    &:hover {
      box-shadow: 0 rem(2) rem(100) rgba(black, 0.7);

      img {
        opacity: 0.8;
      }
    }

    img {
      position: relative;
      left: rem(-3);
      transition: all 0.3s;
    }
  }
}

.feeds {
  padding-bottom: rem(40);

  .feed-wrapper {
    padding-top: rem(100);
    img {
      display: inline-block;
      height: rem(80);
    }

    &.fw-bg {
      background-image: url('../img/browser.svg');
      background-repeat: no-repeat;
      background-size: 100% auto;
      padding-top: 30%;
      padding-bottom: rem(60);

      img {
        height: rem(100);
      }
    }
  }

  @include responsive($view-md) {
    .feed-wrapper {
      background-image: none !important;
      padding-top: rem(40) !important;
      padding-bottom: 0 !important;

      img {
        height: rem(60) !important;
      }
    }
  }
}

.feeds-owl {
  padding: rem(110) 0 rem(80);
  background-image: url('../img/browser.svg');
  background-repeat: no-repeat;
  background-position: top center;

  .owl-img-wrapper {
    padding: rem(40);
  }
  .owl-item {
    &.el-1 img {
      transform: translateX(-50%);
    }

    &.el-2 img {
      transform: translateX(-80%);
    }

    &.el-3 img {
      transform: scale3d(1.5, 1.5, 1.5);
    }

    &.el-4 img {
      transform: translateX(80%);
    }

    &.el-5 img {
      transform: translateX(50%);
    }
  }

  img {
    display: inline-block !important;
    max-width: rem(80);
    transition: transform 0.6s linear;
  }
}

.inspiration {
  .quote-card {
    text-align: left;
    font-size: rem(18);
    @include font-weight('medium');
    line-height: rem(24);
    padding: rem(24);
    background-color: #F9F9F9;
    border-radius: rem(10);

    .quote {
      margin-bottom: rem(16);
    }

    .author {
      color: $color-primary;
      @include font-weight('bold');
    }
  }

  .btn-group {
    margin: rem(80) 0 rem(40);
  }

  .inspiration-companies {
    color: $color-text-light;
    font-size: rem(18);

    .company-logos {
      margin-top: $gutter-lg;

      img {
        height: rem(34);
        margin: 0 rem(38);
      }
    }
  }

  @include responsive($view-sm) {
    .quote-card {
      margin-bottom: rem(24);
    }

    .btn-group {
      margin: 0 0 rem(24);
    }

    .inspiration-companies {
      .company-logos {
        margin-top: rem(20);
        img {
          margin: rem(12);
        }
      }
    }
  }
}

.support-products {
  padding-top: rem(40);
}

.product-box {
  padding: rem(40) rem(30) 0;
  border-radius: rem(10);
  margin-top: rem(25);
  overflow: hidden;

  &.pb-ios {
    background-color: $color-secondary;
  }

  &.pb-android {
    background-color: $color-primary;
    margin-top: 0;

    img {
      max-height: rem(200);
    }
  }

  &.pb-firefox {
    background-color: $color-dark;
  }

  span {
    font-size: rem(13);
    @include font-weight('medium');
    color: rgba(white, 0.8);
  }

  h4 {
    margin: rem(5) 0 rem(15);
    font-size: rem(22);
    @include font-weight('bold');
    color: white;
  }

  p {
    font-size: rem(16);
    color: white;
  }

  img {
    display: block;
    margin: rem(30) auto 0;
    max-height: rem(150);
  }

  @include responsive($view-md) {
    img {
      max-width: 100%;
    }
  }

  @include responsive($view-sm) {
    margin-top: 0;
    margin-bottom: rem(24);

    &.pb-android {
      img {
        position: relative;
        top: rem(50);
        margin-top: rem(-30);
      }
    }
  }
}

.support-features {
  margin-top: rem(80);

  .feature-box {
    padding: 0 rem(20);
    margin-bottom: rem(80);

    img {
      max-height: rem(64);
    }

    h5 {
      margin: rem(5) 0 rem(10);
      @include font-weight('bold');
      font-size: rem(22);
      color: $color-title;
    }

    p {
      font-size: rem(16);
    }
  }

  @include responsive($view-sm) {
    margin-top: rem(60);

    .feature-box {
      margin-bottom: rem(40);
    }
  }
}

.folks-list {
  a {
    display: block;

    &:hover h5:after {
      width: 100%;
    }
  }

  img {
    display: block;
    max-width: rem(100);
    margin: 0 auto;
  }

  h5 {
    position: relative;
    display: inline-block;
    @include font-weight('bold');
    font-size: rem(22);
    color: $color-title;
    margin: rem(10) 0;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      left: 0;
      top: 100%;
      background-color: $color-title;
      transition: width 0.2s;
    }
  }

  p {
    margin-bottom: rem(80);
  }

  @include responsive($view-sm) {
    p {
      margin-bottom: rem(40);
    }
  }
}
