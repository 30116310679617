/************************************
     CORE FILE TO IMPORT STYLES
           STYLE
************************************/

@import "variables";
@import "mixins";
@import "config";
@import "grid";
@import "helpers";
@import "fonts";
@import "elements";

/*** PARTIALS ***/
@import "partials/header";
@import "partials/footer";
// @import "partials/sidebar";

/*** PAGES ***/
@import "pages/home";
@import "pages/content";
@import "pages/terms";

.mb-form-group-tk {
  position: absolute;
  left: -5000px;
}
