/************************************
     CONFIGURATION & RESET
************************************/

/****** GOOGLE FONTS IMPORT ******/
//@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');

*, *:before, *:after {
  box-sizing: border-box;
  /*** Optional ***/
  margin: 0;
  padding: 0;
}

html, body {
  margin: 0;
  padding: 0;
  /***  Optional ***/
  //height: 100%;
}

html {
  font-size: 1rem;
}

body {
  font-family: $global-font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  text-align: center;
  color: $color-text;
}

a {
  color: inherit;
  /*** Or ***/
  //color: $color-link;
  text-decoration: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
}

h1, h2 {
  color: $color-title;
  font-size: rem(64);
  line-height: rem(70);
  margin-bottom: rem(16);

  @include responsive($view-sm) {
    font-size: rem(42);
    line-height: rem(50);

    br {
      display: none;
    }
  }

  @include responsive($view-xs) {
    font-size: rem(32);
    line-height: rem(40);
  }
}

h3 {
  color: $color-text;
  font-size: rem(28);
  line-height: rem(38);
  margin-bottom: rem(56);
  @include font-weight('regular');

  @include responsive($view-sm) {
    font-size: rem(18);
    line-height: rem(26);
    margin-bottom: rem(32);

    br {
      display: none;
    }
  }
}

section {
  padding: rem(120) 0;

  &.sc-dark {
    background-color: $color-dark;

    h1, h2 {
      color: white;

      a {
        text-decoration: underline;
      }
    }

    h3 {
      color: #cccccc;

      a {
        text-decoration: underline;
      }
    }
  }

  .section-header {
    max-width: rem(816);
    margin: 0 auto;
  }

  @include responsive($view-sm) {
    padding: rem(80) 0;
  }
}
