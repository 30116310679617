@font-face {
  font-family: 'Inter UI';
  src: url('../fonts/inter-ui-regular-webfont.woff2') format('woff2'),
       url('../fonts/inter-ui-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../fonts/inter-ui-medium-webfont.woff2') format('woff2'),
       url('../fonts/inter-ui-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter UI';
  src: url('../fonts/inter-ui-bold-webfont.woff2') format('woff2'),
       url('../fonts/inter-ui-bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
