.ft-newsletter {
  a {
    color: $color-primary;
    font-size: rem(22);

    &:hover {
      color: lighten($color-primary, 8%);
    }
  }

  .mb-success-message,
  .mb-error-message {
    margin-top: rem(40);
    color: white;
    font-size: rem(20);
  }

  form {
    margin-top: rem(40);

    .mb-form-group {
      display: inline-block;
      position: relative;

      #mb-email:focus + #mb-email-error {
        display: block !important;
      } 

      #mb-email-error {
        display: none !important;
        position: absolute;
        width: auto;
        left: 30px;
        top: 100%;
        margin-top: 15px;        
        color: white;
        text-transform: italic;
      }
    }

    .mb-form-group-tk {
      position: absolute;
      left: -5000px;
    } 

    input {
      display: inline-block;
      width: rem(400);
      padding: 0 rem(30);
      height: rem(61);
      font-family: $global-font;
      font-size: rem(18);
      color: white;
      background-color: rgba(black, 0.2);
      border: none;
      border-radius: rem(8);

      &:focus, &:active {
        outline: none;
      }
    }

    .btn {
      border: none;
      margin-left: rem(24);
    }
  }

  @include responsive($view-sm) {
    form {
      margin-top: 0;

      .mb-form-group {
        display: block;

        #mb-email-error {
          top: 0;
          margin-top: -10px;        
        }
      }
      
      input {
        width: 100%;
        margin-top: rem(20);
      }
      .btn {
        width: 100%;
        margin-left: 0;
        margin-top: rem(14);
      }
    }
  }
}

.ft-nav {
  margin-top: rem(120);
  li {
    display: inline-block;
    margin: 0 rem(35) rem(14);

    a {
      display: block;
      color: #BEC3C9;
      font-size: rem(16);
      @include font-weight('medium');

      &:hover {
        color: white;
      }
    }
  }

  @include responsive($view-sm) {
    margin-top: rem(70);

    li {
      display: block;
      margin-bottom: rem(20);
    }
  }
}
