.btn {
  display: inline-block;
  padding: rem(17) rem(27);
  font-family: $global-font;
  font-size: rem(18);
  background-color: transparent;
  color: $color-text;
  border-radius: rem(8);
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  @include font-weight('bold');

  &:focus, &:active {
    outline: none;
  }

  .btn-group & {
    margin: 0 rem(12);
  }

  &.btn-primary {
    color: white;
    background-color: $color-primary;

    &:hover {
      background-color: lighten($color-primary, 2%);
    }
  }

  &.btn-light {
    color: $color-text;
    background-color: $color-light;

    &:hover {
      background-color: darken($color-light, 3%);
    }
  }

  &.btn-dark {
    color: white;
    background-color: rgba(#8D949E, 0.2);
    border: none;

    &:hover {
      background-color: rgba(#8D949E, 0.25);
    }
  }

  &.btn-sm {
    padding: rem(10) rem(15);
    font-size: rem(16);
  }

  @include responsive($view-sm) {
    width: 100%;

    .btn-group & {
      margin: rem(8) 0;
    }
  }
}
